import React from "react"
import "../../css/style.css"
import ReactMarkdown from "react-markdown"

const ContentRenderer = ({ content }) => {
  function getContent(block) {
    switch (block.strapi_component) {
      case "faq-content.content-text":
        return (
          <ReactMarkdown className="body-benefits__text reactMarkDown">
            {block.text_content.data.text_content}
          </ReactMarkdown>
        )
      case "faq-content.one-image":
        var alt = block.image.alternativeText ?? ""
        return (
          <div
            className="body-benefits__images body-benefits__images_second"
            style={{ marginBottom: "25px" }}
          >
            <div className="body-benefits__image body-benefits__image_second">
              <picture>
                <source
                  srcSet={block.image.localFile.childImageSharp.fixed.srcSet}
                  type="image/webp"
                />
                <img
                  src={block.image.localFile.childImageSharp.fixed.src}
                  alt={alt}
                />
              </picture>
            </div>
          </div>
        )
      case "faq-content.two-images":
        var alt_first = block.first_image.alternativeText ?? ""
        var alt_second = block.second_image.alternativeText ?? ""
        return (
          <div
            className="body-benefits__images body-benefits__images_first"
            style={{ marginBottom: "25px" }}
          >
            <div className="body-benefits__image body-benefits__image_first">
              <picture>
                <source
                  srcSet={
                    block.first_image.localFile.childImageSharp.fixed.srcSet
                  }
                  type="image/webp"
                />
                <img
                  src={block.first_image.localFile.childImageSharp.fixed.src}
                  alt={alt_first}
                />
              </picture>
            </div>
            <div className="body-benefits__image body-benefits__image_first">
              <picture>
                <source
                  srcSet={
                    block.second_image.localFile.childImageSharp.fixed.srcSet
                  }
                  type="image/webp"
                />
                <img
                  src={block.second_image.localFile.childImageSharp.fixed.src}
                  alt={alt_second}
                />
              </picture>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  return <>{content.map(item => getContent(item))}</>
}

export default ContentRenderer
